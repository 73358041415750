// home-hero .mobile .desktop
const width = window.innerWidth;
const pixelRatio = window.devicePixelRatio || 1;
const realWidth = width * pixelRatio;

let source = '';
let from = '';

const video = {
    mobile: {
        sd: 'https://player.vimeo.com/external/273519358.sd.mp4?s=1851f3cfd91df9014701c66842f78ad0399a99ab&profile_id=164',
        largesd: 'https://player.vimeo.com/external/273519358.sd.mp4?s=1851f3cfd91df9014701c66842f78ad0399a99ab&profile_id=165',
        hd: 'https://player.vimeo.com/external/273519358.hd.mp4?s=ace5e0fb87f2264610291c4847a1520492cf9971&profile_id=174',
    },
    desktop: {
        sd: 'https://player.vimeo.com/external/273519372.sd.mp4?s=8b99b0cbfd07f98363aa644ad8573cf637695918&profile_id=164',
        largesd: 'https://player.vimeo.com/external/273519372.sd.mp4?s=8b99b0cbfd07f98363aa644ad8573cf637695918&profile_id=165',
        hd: 'https://player.vimeo.com/external/273519372.hd.mp4?s=0a66fc0a38e8ec3797145ef87c344f03c12dae47&profile_id=174',
        fullhd: 'https://player.vimeo.com/external/273519372.hd.mp4?s=0a66fc0a38e8ec3797145ef87c344f03c12dae47&profile_id=175',
    }
};

//1080*1920 -fullhd
//720*1280 - hd
//540*960 - largesd
//360*640 - sd
if (width <= 768) {
    //mobile
    from = 'mobile';
    source = 'sd';
    if (realWidth >= 540) source = 'largesd';
    if (realWidth >= 720 ) source = 'hd';
} else {
    //desktop
    from = 'desktop';
    source = 'largesd';
    if (realWidth >= 1280) source = 'hd';
    if (realWidth >= 1920) source = 'fullhd';
}

const videoSource = video[from][source];
const videoElement = document.querySelector(`.cover__video.${from}`);
const videoSourceElement = document.createElement('source');
videoSourceElement.src = videoSource;

videoElement.appendChild(videoSourceElement);
videoElement.load();
videoElement.play();


function scrollToJoin() {
    scrolify(document.querySelector('.cta'), 600, 'easeInOutQuint');
}
